import React from "react";
import {createMemoryHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product

import LandingPage from "./LandingPage/LandingPage.jsx";
import ContactPage from "./ContactPage/ContactPage.jsx";
import StadlPage from "./StadlPage/StadlPage.jsx";
import LieferservicePage from "./LieferservicePage/LieferservicePage";
import WirtshausPage from "./WirtshausPage/WirtshausPage";
import DatenschutzPage from "./DatenschutzPage/DatenschutzPage";
import ImpressumPage from "./ImpressumPage/ImpressumPage";

let hist = createMemoryHistory();

const indexPage = () => (
  <Router history={hist}>
    <Switch>
        <Route path="/wirtshaus" component={WirtshausPage} />
        <Route path="/lieferservice" component={LieferservicePage} />
        <Route path="/stadl" component={StadlPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/datenschutz" component={DatenschutzPage} />
        <Route path="/impressum" component={ImpressumPage} />
        <Route path="/" component={LandingPage} />
    </Switch>
  </Router>
);
export default indexPage;